import PropTypes from 'prop-types'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { MobileNav } from '/src/layouts/dashboard/mobile-nav'
import { SideNav } from './side-nav'
import { TopNav } from './top-nav'
import { useMobileNav } from './use-mobile-nav'
import MuiDrawer from '@mui/material/Drawer'
import { withRoleBasedGuard } from '/src/hocs/with-role-based-guard'
import { useEffect, useState } from 'react'
import { useSettings } from '/src/hooks/use-settings'
import TutorialModal from '/src/components/tutorial-modal'
import { TUTORIAL_IDS } from '/src/constants/tutorials'
import { useAuth } from '/src/hooks/use-auth'
import { useEngagementTimeTracker } from '/src/hooks/react-ga4/use-engagement-time-tracker'
import { useButtonClickTracker } from '/src/hooks/react-ga4/use-button-click-tracker'
import { identifyUserForSurvey } from '/src/refiner/functions'
import { useParams } from 'react-router'
import { localStorageService } from '/src/services/local-storage-service'
import { USER_STATUS } from '/functions/shared/constants/account-statuses'

export const SIDE_NAV_WIDTH = 240

const openedMixin = (theme) => ({
  width: SIDE_NAV_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`
  }
})

const VerticalLayoutRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  display: 'flex',
  flexShrink: 1,
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  },
  transition: theme.transitions.create('padding', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(!open && {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: `calc(${theme.spacing(11)} + 1px)`
    },
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
})

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: SIDE_NAV_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export const VerticalLayout = withRoleBasedGuard((props) => {
  const { children, sections = [], isSelfServiceOn } = props
  const [isOpen, setDrawerIsOpen] = useState(true)
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const mobileNav = useMobileNav()
  const settingsContext = useSettings()
  const { user } = useAuth()
  const { evaluationId } = useParams()

  useButtonClickTracker()

  useEngagementTimeTracker({
    eventParameters: {
      eventName: 'time_spent_on_page',
      action: 'page_view',
      category: 'user_engagement',
      company_name: user.companyName,
      user_uid: user.uid,
      ...(evaluationId ? { evaluation_id: evaluationId } : {})
    }
  })

  identifyUserForSurvey({
    id: user.uid,
    company: user.companyName,
    userRoles: user.userRoles.join(', ')
  })

  useEffect(() => {
    if (isOpen && lgUp) {
      settingsContext.handleSaveSideNavWidth(240)
    } else if (!isOpen && lgUp) {
      settingsContext.handleSaveSideNavWidth(89)
    } else {
      settingsContext.handleSaveSideNavWidth(0)
    }

    // Remove isPurchase from local storage when the user is in the dashboard
    if (localStorageService.getItem('isPurchase')) {
      localStorageService.removeItem('isPurchase')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, lgUp])

  // Check if the user has completed the tutorial
  const displayDemoTutorial =
    !user?.tutorials?.find(
      (tutorial) => tutorial.name === TUTORIAL_IDS.DEMO_TUTORIAL
    )?.completed && user.status === USER_STATUS.DEMO

  return (
    <>
      <TopNav
        onMobileNavOpen={mobileNav.handleOpen}
        isSelfServiceOn={isSelfServiceOn}
      />
      {lgUp && (
        <SideNav
          sections={sections}
          isOpen={isOpen}
          setDrawerIsOpen={setDrawerIsOpen}
        />
      )}
      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}

      <VerticalLayoutRoot open={isOpen}>
        <VerticalLayoutContainer id="vertical-layout-container">
          {displayDemoTutorial ? (
            <TutorialModal
              {...{
                tutorialId: TUTORIAL_IDS.DEMO_TUTORIAL,
                user,
                isRequired: true
              }}
            />
          ) : null}
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  )
})

VerticalLayout.propTypes = {
  children: PropTypes.node,
  sections: PropTypes.array
}
