import { ROLES } from '/functions/shared/constants/roles'

const faqs = [
  {
    faqId: 'General 1',
    question: 'How does Incompass work?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Incompass works through analysis of data from peer reviews. Once all participants completes reviewing their colleagues, Incompass takes a macroscopic view at all the peer reviews and produces optimal scores for all participants, leveraging the wisdom of the crowd and minimizing bias through advanced statistical methods.'
  },
  {
    faqId: 'General 2',
    question:
      'What should I do if I am unsure how to assess someone against a particular behavior?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "If you genuinely can't assess a colleague for a particular behavior, you can de-select them by unticking the box to the left of their name. You should only select people you work closely with and, therefore, can make an assessment on how they showcase the company behaviors."
  },
  {
    faqId: 'General 3',
    question: 'How do I provide feedback for my colleagues?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "You'll use sliders to indicate how well you think your colleagues display each behavior. You can also provide written feedback, which can be enhanced with AI-powered suggestions to improve your comments."
  },
  {
    faqId: 'General 4',
    question: 'How does the self-scoring work?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "If self-scoring is enabled for the review, you'll be asked to rate yourself on each behavior using the same slider scale as you use for your colleagues. This allows for a comparison between your self-perception and how others perceive you."
  },
  {
    faqId: 'General 5',
    question: 'How should I approach deciding which colleagues to select?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "The selection page will be pre-populated for you, but you should think carefully about who to include. If it doesn't make sense to include someone who has been pre-selected, you can remove them. Remember to include those that you can give meaningful feedback to. If you don't know enough people for review, you can submit a request to decrease the number by clicking 'Struggling to choose x colleagues?' on the top-right side of the page. This will trigger a request for your manager to review."
  },
  {
    faqId: 'General 7',
    question: 'Are my reviews anonymous?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'The feedback you submit is completely anonymous (including for managers and administrators) and cannot be attributed to you.  The feedback and scores you receive will be visible to your manager and (where applicable) their manager, and to administrators.'
  },
  {
    faqId: 'General 8',
    question: 'Who can see my results?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Your results will be shared with your manager, and (where applicable) their manager, providing an opportunity for a productive conversation about your personal and professional growth. As administrators, the People team will also be able to see your result.'
  },
  {
    faqId: 'General 11',
    question:
      'What should I do if I need to change the colleagues I have selected?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'If you have already begun your assessment and need to change your selection, you can click on "Select Colleagues" on the progress bar re-select colleagues. Your reviews for the already-selected colleagues will be saved, so you won\'t lose any data.'
  },
  {
    faqId: 'General 13',
    question:
      "As a manager, how can I use the analytics panel to understand my team's performance?",
    visibleTo: [ROLES.MANAGER],
    answer:
      "The analytics panel provides comprehensive insights about your team's performance. You can view reports on your team's performance, analyze the results of your direct reports, and use this information to guide learning and development opportunities for your team members. You can click on rows in the analytics panel to see more details about the performance of a particular team member."
  },
  {
    faqId: 'General 14',
    question: 'As a manager, how can I track the progress of ongoing reviews?',
    visibleTo: [ROLES.MANAGER],
    answer:
      'You can use the "Review Cycles" panel in your dashboard to stay informed with real-time updates on ongoing reviews and track the progress of your team. This panel provides an overview of the current review cycle status and completion rates.'
  },
  {
    faqId: 'General 16',
    question:
      'What should I do if a team member requests to decrease their selected people count to review?',
    visibleTo: [ROLES.MANAGER],
    answer:
      'You\'ll receive a notification on the review cycles tab under manager dashboard. Consider their workload and familiarity with colleagues before approving or denying the request. You can manage these requests through the "Requests" section in your manager dashboard.'
  },
  {
    faqId: 'General 17',
    question: 'As an admin, how can I manage review cycles?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'As an admin, you can manage review cycles through the admin dashboard. You can create new review cycles, customize review questions, set review periods, and monitor the progress of ongoing reviews across the organization. The dashboard provides real-time updates and allows you to make adjustments as needed.'
  },
  {
    faqId: 'General 18',
    question: 'How can I access organization-wide performance insights?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'Incompass provides comprehensive analytics for admins. You can access organization-wide performance insights through the "Analytics" section in the admin dashboard. This includes an overview of the organization\'s overall performance, individual performance metrics, and various charts and reports to help you make informed decisions about workforce development and organizational strategy.'
  },
  {
    faqId: 'General 19',
    question: 'How can I customize the review process for my organization?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'Incompass offers various customization options for the review process. You can adjust settings such as the minimum and maximum number of reviewers, enable or disable self-scoring, set up mandatory inclusions for direct reports or managers, and customize the behaviors or competencies being evaluated. These settings can be accessed and modified in the "Evaluation Settings" section of the admin dashboard.'
  },
  {
    faqId: 'General 20',
    question: 'How do I create a new review cycle?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'To create a new review cycle, navigate to the "Review Cycles" section in the admin dashboard and click on "Create New Review". You\'ll be guided through a process to set up the evaluation name, description, start and end dates, and select the behaviors or competencies to be evaluated. You can also configure specific settings for the cycle, such as reviewer selection criteria and scoring options.'
  },
  {
    faqId: 'General 21',
    question: 'Can I make changes to an ongoing review cycle?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'Yes, you can make certain changes to an ongoing review cycle. However, to maintain the integrity of the review process, some changes may be limited once the cycle has started. You can adjust end dates, add new participants, or make minor tweaks to the configuration. Major changes, such as altering the behaviors being evaluated, are typically not recommended once the cycle is underway. Always consider the impact on participants before making changes to an active cycle.'
  },
  {
    faqId: 'General 22',
    question: 'How can I manage my organization settings?',
    visibleTo: [ROLES.ADMIN],
    answer:
      'You can manage your organization by navigating to the "Organization" section in the admin dashboard. Here, you can manage users, roles, and permissions, as well as upload new users to the platform.'
  }
]

export default faqs
