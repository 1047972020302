import { array, boolean, number, object, string } from 'yup'
import { responsesSchema } from './common/responses.js'
import {
  dataConfigSchema,
  questionsSchema,
  evaluationConfigSchema,
  dateSchema
} from './common/evaluation.js'
import { EMPLOYEE_RELATION_TYPES } from '../../shared/constants/employees.js'
import { MANAGER_REVIEW_STATUS } from '../constants/manager-review-statuses.js'

const reviewResponseSchema = object()
  .shape({
    evaluation: string().required('Evaluation field is required.'),
    people: array()
      .of(
        object()
          .shape({
            fullName: string().required('Full name field is required.'),
            uid: string().required('UID field is required.'),
            seniority: string().required('Seniority field is required.'),
            previousData: object().required('Previous data field is required.'),
            isDirectManager: boolean().required(
              'Is direct manager field is required.'
            )
          })
          .noUnknown()
      )
      .required('People field is required.'),
    responses: responsesSchema.required('Responses field is required.'),
    reviewId: string().required('Review ID field is required.'),
    reviewer: string().required('Reviewer field is required.'),
    status: string().required('Status field is required.'),
    questionIndex: number().required('Question index field is required.'),
    questions: questionsSchema.required('Questions field is required.'),
    dataConfig: dataConfigSchema.required('Data config field is required.'),
    missingManagerOrDirectReport: array()
      .of(
        string().oneOf([
          EMPLOYEE_RELATION_TYPES.DIRECT_MANAGER,
          EMPLOYEE_RELATION_TYPES.DIRECT_REPORT
        ])
      )
      .required('Missing manager or direct report field is required.')
  })
  .noUnknown()

export const getReview = reviewResponseSchema

export const updateReview = reviewResponseSchema

export const getReviews = array()
  .of(
    object()
      .shape({
        company: string().required('Company field is required.'),
        description: string().required('Description field is required.'),
        name: string().required('Name field is required.'),
        questions: questionsSchema.required('Questions field is required.'),
        status: string().required('Status field is required.'),
        uid: string().required('UID field is required.'),
        startDate: dateSchema
          .nullable()
          .defined('Start date field is required.'),
        endDate: dateSchema.nullable().defined('End date field is required.'),
        expectedEndDate: dateSchema
          .nullable()
          .defined('Expected end date field is required.'),
        evaluationConfig: evaluationConfigSchema,
        review: object()
          .shape({
            status: string().required('Status field is required.'),
            uid: string().required('UID field is required.'),
            managerReviewStatus: string().oneOf(
              Object.values(MANAGER_REVIEW_STATUS)
            )
          })
          .required('Review field is required.')
      })
      .noUnknown()
  )
  .ensure()
